import React from "react";
import { Link } from "react-scroll";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg px-4 pt-4 pb-2 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>

        <p className="text-m sm:text-xl mt-5">
          My growing interest in culture and diversity, along with being born
          and raised in the multicultural city of Dubai, has allowed me to
          increase my cultural awareness. I believe this will aid me to
          acculturate faster to a new environment. I possess the
          open-mindedness, that enables me to appreciate varying opinions, along
          with the diverse knowledge and experiences, enriching the community
          around me. This would help me tackle any new environment through a
          blended perspective.
          <br />
          <br />
          I am passionate about my career while possessing the ability to
          comprehend the problems people face with respect and empathy. I
          believe in my strengths and, with a positive attitude, work on my
          weaknesses to overcome challenges.
          <br />
          <br />
          📧 Feel free to contact me{" "}
          <Link
            smooth
            duration={500}
            className="text-blue-500 cursor-pointer"
            to="contact"
          >
            here
          </Link>{" "}
          to get in touch. <br />
          <br />
        </p>
      </div>
    </div>
  );
};

export default About;
